import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/tailwind.css";
import "./assets/css/global.scss";
import "./assets/css/style.css";
import VueFeather from "vue-feather";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  configure,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import { required } from "vee-validate/dist/rules";
import displaySnackbar from "../src/services/utils/snackbar";
import VueSplide from "@splidejs/vue-splide";
import VueApexCharts from "vue-apexcharts";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";

import VueMeta from 'vue-meta'


Vue.config.productionTip = false

Vue.use(VueSplide)

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

dayjs.extend(LocalizedFormat);
dayjs().format("L LT");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueSplide);
Vue.prototype.$displaySnackbar = displaySnackbar;

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.component("vue-feather", VueFeather);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });

extend("required", {
  ...required,
  message: "The {_field_} field is required",
});

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

localize("en", en);









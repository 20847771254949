import Vue from 'vue'
import Vuex from 'vuex'
import snackBar from "@/store/snackBar";
import dashboardComponent from "@/store/dashboardComponent";
import applyToCamp from "@/store/applyToCamp";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    snackBar,
    dashboardComponent,
    applyToCamp
  }
})

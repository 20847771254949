import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path:"",
    name: 'HomePage',
    component : () => import('@/views/IndexPage')
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import('@/views/ContactView')
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import('@/views/BlogView')
  },
  {
    path: "/about",
    name: "About",
    component: () => import('@/views/AboutView')
  },
  {
    path: "/register",
    name: "Register",
    component: () => import('@/views/RegisterView')
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView")
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassowrdView")
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/NewPassowrdView")
  },
  {
    path: "/guardian-onboarding",
    name: "GuardingOnboarding",
    component: () => import("@/views/GuardianOnboarding")
  },

  {
    path: "/add-volunteer",
    name: "AddVolunteer",
    component: () => import("@/views/AddVolunteer")
  },
  {
    path: "/dashboard/add-candidate",
    name: "AddCandidate",
    component: () => import("@/views/AddCandidate"),
    meta: {
      auth: "authenticated"
    }
  },
  {
    path: "/dashboard",
    component: () => import("@/views/DashboardView"),
    children : [
      {
        path : "",
        name : "MainDashboard",
        component: () => import("@/components/MainDashboard"),
        meta: {
          auth: "authenticated"
        }
      },

      {
        path: "/dashboard/profile",
        name: "GuardianProfile",
        component: () => import("@/views/GuardianProfile"),
        meta: {
          auth: "authenticated"
        }
      },
      {
        path: "/dashboard/settings",
        name: "Settings",
        component: () => import("@/components/reuseables/AccountSetting"),
        meta: {
          auth: "authenticated"
        },
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  },

})

router.beforeEach((to, from, next) => {

  let userData = JSON.parse(sessionStorage.getItem('userData'))
  if (to.meta.auth) {
    if (!userData) {
      next({
        name: 'HomePage'
      })
    }
  }
  next();
})

export default router
